@import "@/src/styles/common.scss";
.extend-button {
    position: relative;
    font-size: 0;
    &::after {
        content: "";
        position: absolute;
        left: -0.1rem;
        top: -0.1rem;
        bottom: -0.1rem;
        right: -0.1rem;
    }
}
