@import "@/src/styles/common.scss";
.firmoo-login-fb {
    width: 4rem;
    height: 0.7rem;
    background: rgba(61, 88, 156, 1);
    border-radius: 0.08rem;
    margin: 0 auto;
    font-size: 0.28rem;
    font-family: var(--font-montserrat);
    color: rgba(255, 255, 255, 1);
    line-height: 0.7rem;
    text-align: center;
    position: relative;
    .facebook-hidden {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        width: 100%;
        height: 100%;
    }
}
.circle {
    margin-left: 10px;
    margin-top: 15px;
    opacity: 1;
    width: 20px;
    height: 20px;
    animation: loading 0.6s infinite linear;
    transition: 0.3s opacity linear;
    transform-origin: center calc(50% - 10px);
}

@keyframes loading {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(360deg) translateY(-50%);
    }
}
